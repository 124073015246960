@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9; /* Light gray background */
}

h1, h2, h3, h4, h5, h6 {
  color: #333; /* Dark gray text */
}

p {
  line-height: 1.6;
  color: #555;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.container {
  padding: 16px;
  margin: auto;
  max-width: 1200px;
}
/* Override text color for dark mode */
.dark-mode {
  --text-color: #000; /* Black text for dark mode */
}

body.dark {
  color: var(--text-color);
}