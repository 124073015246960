@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --ring: 240 5% 64.9%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --ring: 240 4.9% 83.9%;
  }
}

.refresh-icon {
  transition: transform 0.2s ease;
}

.refresh-icon:hover {
  transform: rotate(90deg);
}

/* Adjustments for Sets on Fire ratings */
.fire-rating {
  display: inline-flex;
  gap: 2px;
}

.fire-rating svg {
  transition: color 0.2s ease;
}

/* Card specific styles */
.set-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.set-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* Tab styles */
.tab-content {
  transition: opacity 0.15s ease;
}

.tab-content[hidden] {
  opacity: 0;
}